<template>
    <b-form-group
    >
      <template #label v-if="!compactMode">
        <b-checkbox
          v-model="d_checked"
          :disabled="disabled"
          @change="onChange"
          :switch = "switched"
        >
          <slot name="caption">
            <span :class="{ 'required' : required}">
              <slot name="caption-text">{{ captionLabel }}</slot>
            </span>
            <span v-if="hasSlot('info-helper-placeholder')">&nbsp;&nbsp;&nbsp;</span>
            <slot name="info-helper-placeholder"></slot>
          </slot>
        </b-checkbox>
      </template>

      <b-input-group >
        <template #prepend v-if="compactMode || prepend || hasSlot('prepend')">
          <b-input-group-text v-if="compactMode">
            <slot name="caption">
              <span :class="{ 'required' : required}">
                <slot name="caption-text">{{ captionLabel }}</slot>
              </span>
              <span v-if="hasSlot('info-helper-placeholder')">&nbsp;&nbsp;&nbsp;</span>
              <slot name="info-helper-placeholder">
              </slot>
            </slot>
          </b-input-group-text>
          <slot name="prepend">
            <b-input-group-text v-if="prepend">{{ prepend }}
            </b-input-group-text>
          </slot>
        </template>
        <b-checkbox
          v-if="compactMode || prepend || hasSlot('prepend')"
          v-model="d_checked"
          :disabled="disabled"
          @change="onChange"
          :switch = "switched"
        />
        <b-form-invalid-feedback :state="state"><slot name="invalid-feedback">{{ invalidFeedback }}</slot></b-form-invalid-feedback>
        <b-form-valid-feedback :state="state"><slot name="valid-feedback">{{ validFeedback }}</slot></b-form-valid-feedback>
      </b-input-group>


      <!--<slot v-if="!compactMode"></slot>-->
    </b-form-group>

<!--
    <b-form-checkbox
      v-model="checked"
      :value="value"
      :unchecked-value="unchecked-value"
    >
      <slot name="caption">
        <span :class="{ 'required' : required}">
          <slot name="caption-text">{{ caption }}</slot>
        </span>
        <span v-if="hasSlot('info-helper-placeholder')">&nbsp;&nbsp;&nbsp;</span>
        <slot name="info-helper-placeholder"></slot>
      </slot>
      <b-form-invalid-feedback :state="state"><slot name="invalid-feedback">{{ invalidFeedback }}</slot></b-form-invalid-feedback>
      <b-form-valid-feedback :state="state"><slot name="valid-feedback">{{ validFeedback }}</slot></b-form-valid-feedback>
    </b-form-checkbox>-->
</template>

<script>

export default {
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    compactMode: { Type:Boolean, default:false },
    caption: { Type:String, default:''},
    label: {type:String, default:''},
    required: { Type:Boolean, default:false },
    prepend: { type:String },
    append: { type:String },
    validFeedback: {type:String},
    invalidFeedback: {type:String},
    state: {type:Boolean, default:true},
    description:{type:String, default:null},
    checked: { type:Boolean, default:false},
    disabled: {type:Boolean, default:false},
    switch : { type:Boolean, default:false},
  },
  data(){
    return {
      d_checked: this.checked,
    }
  },
  computed:{
    switched(){ return this.switch; },
    captionLabel(){
      if(this.caption != null && this.caption != '')
        return this.caption;
      return this.label;
    },
  },
  methods: {
    hasSlot(slotName = 'default') {
      return !!this.$slots[slotName];
    },
    onChange(newValue)
    {
      this.$emit('change', newValue);
    }
  },
  watch:{
    checked: function(newValue){
      this.d_checked = newValue;
    }
  }
}
</script>


<style scoped>
  span.required::after { content:'* '; color:var(--ffvlAlert);}
</style>
