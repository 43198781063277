<template>
    <div>
        <div :class='{ "not-synchronized": !d_dataSynchronized }'>
            <slot></slot>
        </div>
    </div>
</template>

<script >
export default{
    props:{ value: { type:Boolean, default:false }},
    data(){
        return { d_dataSynchronized: this.value };
    },
    watch:{
        value: function(newValue){
            this.d_dataSynchronized = newValue;
        }
    }
}
</script>


<style>
    .not-synchronized{ background-color: var(--ffvlOrangeLight );}
</style>