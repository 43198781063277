<template>
  <b-modal
    :title="creationMode ? 'Ajout de compétiteur' : 'Modification de compétiteur'"
    centered
    :static="true"
    :lazy="true"
    v-model="modalShown"
    button-size="lg"
    @ok="handleOk"
    @close="close"
    @hidden="hide"
    @show="show"
    ref="dialog"
  >
      <vue-bootstrap-typeahead
          v-show = 'creationMode && !newCompetitor'
          v-model="editSearch"
          :data="searchSuggestions"
          :minMatchingChars="2"
          :serializer="s => s.forSuggestion"
          placeholder="par nom, prénom, n° de licence"
          @hit="editIndividualSearch_Hit"
          prepend="Rechercher"
          ref="individualCompetitorSearchArea"
      >
        <template slot="suggestion" slot-scope="{ data, htmlText }">
          <span v-if="data.matches=='**'" class="full-match" v-html="htmlText"></span>
          <span v-if="data.matches=='*'" class="half-match" v-html="htmlText"></span>
          <span v-if="data.matches==''" class="no-match" v-html="htmlText"></span>
        </template>
      </vue-bootstrap-typeahead>

    <!--</b-input-group>-->
      <helper-note v-show="creationMode" :variant="creationHelperNoteVariant">
          <div>Privilégiez toujours la recherche.<br/>
          Si aucun résultat ne correspond, essayez de modifier l'orthographe.<br/>
          Si, finalement, vous ne trouvez vraiment pas le compétiteur, ou si vous êtes certain qu'il s'agit d'un nouveau compétiteur inconnu dans la base, choisissez "Nouveau" puis saisissez les informations ci-dessous.
          </div>
          <b-button variant="secondary" size="sm" @click="newCompetitor_click" :disabled="newCompetitor">Nouveau</b-button>
          <b-button variant="secondary" size="sm" @click="cancelNewCompetitor_click" :disabled="!newCompetitor">Annuler Nouveau compétiteur</b-button>
      </helper-note>
<!--      <b-alert v-model="creationMode" variant="info">
<div>Privilégiez toujours la recherche.<br/>
          Si aucun résultat ne correspond, essayez de modifier l'orthographe.<br/>
          Si, finalement, vous ne trouvez vraiment pas le compétiteur, ou si vous êtes certain qu'il s'agit d'un nouveau compétiteur inconnu dans la base, choisissez "Nouveau" puis saisissez les informations ci-dessous.
          </div>
          <b-button variant="secondary" size="sm">Nouveau</b-button>      </b-alert>-->
    <b-form ref="form" @submit.stop.prevent="editIndividualCompetitor_Ok">

      <checkbox-form-field label='Concours en "open"' v-if="competitionIsOpen && !creationMode" caption='Concours en mode "open"' v-model="editingCompetitorIsOpen" switch>
      <br/>
      </checkbox-form-field>

      <form-field caption="Prénom" required>
        <b-form-input type="text"
          v-model="editingCompetitorFirstname"
          required
          :disabled="creationMode && !newCompetitor"
          :state="editingCompetitorFirstnameIsValid"
          @blur="checkEditingCompetitorFirstnameValidity"
          @input="editingCompetitorFirstnameIsValid = null"
          invalidFeedBack="Le prénom est obligatoire."
        />
      </form-field>
      <form-field caption="Nom" required>
        <b-form-input type="text"
          v-model="editingCompetitorLastname"
          required
          :disabled="creationMode && !newCompetitor"
          :state="editingCompetitorLastnameIsValid"
          @blur="checkEditingCompetitorLastnameValidity"
          @input="editingCompetitorLastnameIsValid = null"
        />
      </form-field>
      <form-field label="N° de licence FFVL" :required="COMPETITION.COMPETITION_TYPE.isFederal && !COMPETITION.isOpen">
        <b-form-input type="text"
          v-model="editingCompetitorFederalNumber"
          :disabled="creationMode && !newCompetitor"
          :required="COMPETITION.COMPETITION_TYPE.isFederal && !COMPETITION.isOpen"
          :state="editingCompetitorFederalNumberIsValid"
          @blur="checkEditingCompetitorFederalNumberValidity"
          @input="editingCompetitorFederalNumberIsValid = null"
        />
      </form-field>

      <checkbox-form-field label='Concours en "open"' v-if="competitionIsOpen && creationMode" caption='Concours en mode "open"' v-model="editingCompetitorIsOpen" switch>
      </checkbox-form-field>

      <licensee-state 
        v-show="editingCompetitorFederalNumber != null && editingCompetitorFederalNumber.length == 8"
        :federalNumber="editingCompetitorFederalNumber"
        :showSummary="false"
        @change="licenseeState_change"
      >
        <template #prepend="data">
          <b>Etat de la licence {{ data.year }} de {{ editingCompetitorFirstname || editingCompetitorLastname || 'cette personne' }}</b>
        </template>
      </licensee-state>

      <form-field label="Sexe" required v-show="creationMode && newCompetitor">
        <b-form-radio-group
          v-model="editingCompetitorSexe"
          :required="creationMode && newCompetitor"
          :state="editingCompetitorSexeIsValid"
          @blur="checkEditingCompetitorSexeValidity"
          @input="editingCompetitorSexeIsValid = null"
        >
          <b-form-radio value="M" :state="editingCompetitorSexeIsValid">Homme</b-form-radio>
          <b-form-radio value="F" :state="editingCompetitorSexeIsValid">Femme</b-form-radio>
        </b-form-radio-group>
      </form-field>

      <form-field label="birthDate" v-show="creationMode && newCompetitor">
        <!--<b-form-datepicker v-model="editingCompetitorBirthdate" />-->
        <b-form-input type="date"
          v-model="editingCompetitorBirthdate"
          :min="minBirthdateYear + '-01-01'"
          :max="maxBirthdateYear + '-12-31'"
          :state="editingCompetitorBirthdateIsValid"
          @blur="checkEditingCompetitorBirthdateValidity"
          @input="editingCompetitorBirthdateIsValid = null"
        />
      </form-field>
      <form-field label="courriel" v-show="creationMode && newCompetitor">
        <b-form-input type="email"
          v-model="editingCompetitorEmail"
          :state="editingCompetitorEmailIsValid"
          @blur="checkEditingCompetitorEmailValidity"
          @input="editingCompetitorEmailIsValid = null"
         />
      </form-field>
    </b-form>
  </b-modal>
</template>

<script>
import { RST_COMPETITOR, RST_COMPETITION } from "@/LocalDB";
import FormField from "@/components/Panels/FormField.vue";
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
import HelperNote from "@/components/HelperNote";
import CompetitionsConfigurationHelper from "@/services/helpers/competitionsConfigurationHelper";
//import { debounce } from "debounce";
import LicenseeState from "@/components/LicenseeState.vue";
import CheckboxFormField from "@/components/Panels/CheckboxFormField.vue";

export default {
  name: 'IndividualCompetitorEditDialog',
  components:{ FormField, VueBootstrapTypeahead, HelperNote, LicenseeState, CheckboxFormField },
  props: {
    value: { type:Boolean, default: false},
    competition_id : { type: String, required: true},
    level: { type:String, required: true},
    category: { type: String, required: true},
    competitor_id: { type: Object, default: null},
    creationMode: { type:Boolean, default: false},
    search_suggestions: { type:Array, default: null},
  },
  data(){
    return {
      modalShown: this.value,
      editSearch: '',
      //editSearchSuggestions: this.search_suggestions,

      editingCompetitorId: this.competitor_id,
      editingCompetitorFirstname : null, editingCompetitorFirstnameIsValid: null,
      editingCompetitorLastname: null,  editingCompetitorLastnameIsValid: null,
      editingCompetitorFederalNumber: null, editingCompetitorFederalNumberIsValid: null,
      editingCompetitorIsOpen: false,
      editingCompetitorSexe: null, editingCompetitorSexeIsValid: null,
      editingCompetitorBirthdate: null, editingCompetitorBirthdateIsValid: null,
      editingCompetitorEmail: null, editingCompetitorEmailIsValid: null,

      searchSuggestions: [],
      newCompetitor: false,
      creationHelperNoteVariant: 'info',

      d_updated_licensee_state: null,
    };
  },
  computed:{
    COMPETITION(){
      var comp = RST_COMPETITION.query().where("competition_id", this.competition_id).first();
      return comp;
    },
    minBirthdateYear(){
      return new Date().getFullYear() - 80;
    },
    maxBirthdateYear(){
      return new Date().getFullYear() - 5;
    },
    competitionIsOpen(){
      return this.COMPETITION.isOpen;
    },
  },
  methods:{
    checkFormValidity(){
      this.checkEditingCompetitorFirstnameValidity();
      this.checkEditingCompetitorLastnameValidity();
      this.checkEditingCompetitorFederalNumberValidity();
      this.checkEditingCompetitorSexeValidity();
      this.checkEditingCompetitorBirthdateValidity();
      this.checkEditingCompetitorEmailValidity();

      const valid = this.$refs.form.checkValidity();
      return valid;
    },
    handleOk(bvModalEvent){
      bvModalEvent.preventDefault();
      this.editIndividualCompetitor_Ok();
    },
    close(){
      this.$emit("close");
      this.$emit("input", false);
    },
    hide(){
      this.$emit("hide");
      this.$emit("input", false);
    },
    hideDialog(){
      this.$refs['dialog'].hide();
      this.$emit("input", false);
    },
    show(){
      this.reset(!this.creationMode && this.editingCompetitorId != null);
      this.$emit("input", true);
    },
    editIndividualSearch_Hit(selected){
        this.editingCompetitorId = selected;
        this.editingCompetitorFirstname = selected.PEOPLE.firstname;
        this.editingCompetitorLastname = selected.PEOPLE.lastname;
        this.editingCompetitorFederalNumber = selected.PEOPLE.federal_number;
    },
    async editIndividualCompetitor_Ok(){
      if(!this.checkFormValidity()) {
        return;
      }
      await this.$showSaver();
      this.hideDialog();
      //this.saving=true;
      setTimeout(this.completeSaving, 100);
    },
    async completeSaving(){
      //this.saving = true;
      //debounce(this.hideDialog, 100);
      var comp = this.editingCompetitorId;
      var lic = this.d_updated_licensee_state;


      if(this.creationMode){
        if(this.newCompetitor || comp == null){

          comp = CompetitionsConfigurationHelper.create_new_individual_competitor_from_scratch(
              this.COMPETITION.competition_type,
              this.category,
              this.editingCompetitorFirstname,
              this.editingCompetitorLastname,
              this.editingCompetitorFederalNumber,
              this.editingCompetitorSexe,
              this.editingCompetitorBirthdate,
              this.editingCompetitorEmail);
        }

        var catCompetitors = RST_COMPETITOR.query()
                .where("competition_id", this.competition_id)
                .where("level", this.level)
                .where("category", this.category)
                .orderBy("subscription_order")
                .get();
        var lastCatCompetitor = (catCompetitors != null && catCompetitors.length > 0)
                              ? catCompetitors[catCompetitors.length - 1]
                              : null;
        var subscriptionOrder = (lastCatCompetitor != null)
                              ? lastCatCompetitor.subscription_order
                              : 0;

        if(comp != null && comp.category == this.category)
        {
            CompetitionsConfigurationHelper.attach_competitor(this.competition_id, comp.id, this.level, this.category, subscriptionOrder + 1, this.editingCompetitorIsOpen);
            if(lic != null)
              await CompetitionsConfigurationHelper.changeLicenceInfoAsync(lic.federal_number, lic.year, lic.isValid, lic.isCompetitor, lic.isMedicalCertificateValid);
            this.$emit('competitorCreated');
        } else if (comp != null) {
            var competitor = CompetitionsConfigurationHelper.create_new_individual_competitor_from_people(this.COMPETITION.competition_type, this.category, comp.people_id );
            CompetitionsConfigurationHelper.attach_competitor(this.competition_id, competitor.id, this.level, this.category, subscriptionOrder + 1, this.editingCompetitorIsOpen);
            if(lic != null)
              await CompetitionsConfigurationHelper.changeLicenceInfoAsync(lic.federal_number, lic.year, lic.isValid, lic.isCompetitor, lic.isMedicalCertificateValid);
            this.$emit('competitorCreated');
        } else {
            console.log('should not come here !');
            throw 'should not come here !';
            /*competitor = CompetitionsConfigurationHelper.create_new_individual_competitor_from_scratch(this.currentCompetition.competition_type,
                this.editingCompetitorsCategory.code,
                this.editCompetitorFirstname,
                this.editCompetitorLastname,
                this.editCompetitorFederalNumber);
            CompetitionsConfigurationHelper.attach_competitor(this.currentCompetition_id, competitor.id, this.editingCompetitorsLevel.code, this.editingCompetitorsCategory.code, subscriptionOrder + 1);
            */
        }
        this.reset();
        this.$hideSaver();
        this.toastSaved("Compétiteur ajouté");
        this.$emit("ok");
        this.saving = false;
      } else {
        var success = true;
        if(this.editingCompetitorFirstname != this.editingCompetitorId.firstname
          || this.editingCompetitorLastname != this.editingCompetitorId.lastname
          || this.editingCompetitorFederalNumber != this.editingCompetitorId.federal_number)
        {
          success = await CompetitionsConfigurationHelper.updateIndividualCompetitorDataAsync(this.editingCompetitorId.id, this.editingCompetitorFirstname, this.editingCompetitorLastname, this.editingCompetitorFederalNumber);
        }
        if(success && (this.editingCompetitorIsOpen != this.competitor_id.isOpen))
          success = await CompetitionsConfigurationHelper.changeCompetitorIsOpenStatusAsync(this.competition_id, this.editingCompetitorId.id, this.editingCompetitorIsOpen);

        if(success && (lic != null))
            success = await CompetitionsConfigurationHelper.changeLicenceInfoAsync(lic.federal_number, lic.year, lic.isValid, lic.isCompetitor, lic.isMedicalCertificateValid);
        this.reset();
        this.$hideSaver();
        this.toastAsyncSaved(success, "Compétiteur modifié");
        this.$emit("ok");
        this.saving = false;
      }
    },
    newCompetitor_click(){
      this.newCompetitor = true;
      this.creationHelperNoteVariant = 'danger';
    },
    cancelNewCompetitor_click(){
      this.newCompetitor = false;
      this.editingCompetitorFirstnameIsValid = null;
      this.editingCompetitorLastnameIsValid = null;
      this.editingCompetitorFederalNumberIsValid = null;
      this.editingCompetitorSexeIsValid = null;
      this.editingCompetitorBirthdateIsValid = null;
      this.editingCompetitorEmailIsValid = null;
      this.creationHelperNoteVariant = 'info';
    },
    reset(exceptEditionFields = false){
      this.editSearch = '';

      if(!exceptEditionFields){
        this.editingCompetitorId = null;
        this.editingCompetitorFirstname = '';
        this.editingCompetitorLastname = '';
        this.editingCompetitorFederalNumber = '';
        this.editingCompetitorIsOpen = false;
      }
      this.editingCompetitorFirstnameIsValid = null;
      this.editingCompetitorLastnameIsValid = null;
      this.editingCompetitorFederalNumberIsValid = null;
      this.editingCompetitorSexe = null;              this.editingCompetitorSexeIsValid = null;
      this.editingCompetitorBirthdate = null;         this.editingCompetitorBirthdateIsValid = null;
      this.editingCompetitorEmail = null;             this.editingCompetitorEmailIsValid = null;

      this.newCompetitor = false;
      this.creationHelperNoteVariant = 'info';
      this.d_updated_licensee_state = null;
    },
    checkEditingCompetitorFirstnameValidity(){
      this.editingCompetitorFirstnameIsValid = null;
      if((!this.creationMode || this.newCompetitor) && (this.editingCompetitorFirstname == null || this.editingCompetitorFirstname.trim() == ''))
        this.editingCompetitorFirstnameIsValid = false;
      return this.editingCompetitorFirstnameIsValid == null ? true : false;
    },
    checkEditingCompetitorLastnameValidity(){
      this.editingCompetitorLastnameIsValid = null;
      if((!this.creationMode || this.newCompetitor) && (this.editingCompetitorLastname == null || this.editingCompetitorLastname.trim() == ''))
        this.editingCompetitorLastnameIsValid = false;
      return this.editingCompetitorLastnameIsValid == null ? true : false;
    },
    checkEditingCompetitorFederalNumberValidity(){
      this.editingCompetitorFederalNumberIsValid = null;
      if((!this.creationMode || this.newCompetitor) && (this.editingCompetitorFederalNumber == null || this.editingCompetitorFederalNumber.trim() == '') && this.COMPETITION.COMPETITION_TYPE.isFederal && !this.COMPETITION.isOpen )
        this.editingCompetitorFederalNumberIsValid = false;
      return this.editingCompetitorFederalNumberIsValid == null ? true : false;
    },
    checkEditingCompetitorSexeValidity(){
      this.editingCompetitorSexeIsValid = null;
      if(this.creationMode && this.newCompetitor && (this.editingCompetitorSexe == null || this.editingCompetitorSexe.trim() == ''))
        this.editingCompetitorSexeIsValid = false;
      return this.editingCompetitorSexeIsValid == null ? true : false;
    },
    checkEditingCompetitorBirthdateValidity(){
      console.log('checkEditingCompetitorBirthdateValidity', this.editingCompetitorBirthdate);
      this.editingCompetitorBirthdateIsValid = null;
      if(this.editingCompetitorBirthdate != null){
        var y = this.editingCompetitorBirthdate.slice(0, 4);
        if(y < this.minBirthdateYear || y > this.maxBirthdateYear )
          this.editingCompetitorBirthdateIsValid = false;
      }
      return this.editingCompetitorBirthdateIsValid == null ? true : false;
    },
    checkEditingCompetitorEmailValidity(){
      this.editingCompetitorEmailIsValid = null;
      if(this.editingCompetitorEmail != null && this.editingCompetitorEmail != '' && !this.validateEmail(this.editingCompetitorEmail))
        this.editingCompetitorEmailIsValid = false;
      return this.editingCompetitorEmailIsValid == null ? true : false;
    },
    licenseeState_change(newState){
      this.d_updated_licensee_state = newState;
    },
/*    objectsEquals(o1, o2){
      return typeof o1 === 'object' && Object.keys(o1).length > 0
            ? Object.keys(o1).length === Object.keys(o2).length && Object.keys(o1).every(p => this.objectsEquals(o1[p], o2[p]))
            : o1 === o2;
    },
    arraysEquals(a1, a2){
      return a1.length === a2.length && a1.every((o, idx) => this.objectsEquals(o, a2[idx]));
    }*/
  },
  watch:{
    search_suggestions: function (newVal, oldVal){
      if(!newVal || newVal == null)
        newVal = [];
      if(!this.arraysEquals(newVal, oldVal))
        this.searchSuggestions = newVal;
    },
    value: function(newVal)
    {
      this.modalShown = newVal;
    },
    competitor_id(newVal)
    {
      this.reset();
        this.editingCompetitorId = newVal;
        console.log('watch competitor_id', newVal);
        if(this.editingCompetitorId){ 
          this.editingCompetitorFirstname = newVal.firstname;
          this.editingCompetitorLastname = newVal.lastname;
          this.editingCompetitorFederalNumber = newVal.federal_number;
          this.editingCompetitorIsOpen = newVal.isOpen;
        }
    }
  }
}
</script>

<style scoped>
  .full-match { color: green; font-weight: bolder;}
  .half-match { color: goldenrod;}
  .no-match{ color: red;}
</style>
