<template>
<div>
   <data-sync-panel v-model="d_dataSynchronized">
        <slot name="prepend" v-bind="{'federal_number': d_federal_number, 'year': d_year, 'people_name': d_people_name}"></slot>
        <div v-if="d_showSummary" >
        </div>
        <div v-else>
            <div v-if="d_showYear">{{ d_year}} : </div>
            <div v-if="d_showLicenceNumber">{{ d_federal_number }}</div>
            <checkbox-form-field class="row-padding"
                switch
                :checked="d_isValid"
                :disabled="d_disabled"
                caption="Licence souscrite"
                @change="isValid_change"
            ></checkbox-form-field>
            <checkbox-form-field class="row-padding"
                switch
                :checked="d_isCompetitor"
                :disabled="d_disabled || !d_isValid"
                caption='Avec option "Carte compétition"'
                @change="isCompetitor_change"
            ></checkbox-form-field>
            <checkbox-form-field class="row-padding"
                switch
                :checked="d_isMedicalCertificateValid"
                :disabled="d_disabled || !d_isValid || !d_isCompetitor"
                caption="Licence validée"
                @change="isMedicalCertificateValid_change"
            ></checkbox-form-field>
        </div>
    </data-sync-panel>
</div>
</template>

<script >
import { LICENSEE } from "@/LocalDB";
import DataSyncPanel from '@/components/Panels/DataSyncPanel.vue';
import CheckboxFormField from './Panels/CheckboxFormField.vue';

export default{
  components: { CheckboxFormField, DataSyncPanel },
  props:{ 
    people_id: { type:Number, required: false},
    peopleName: {type: String, default: ''},
    year: { type:Number, dafeult:null },
    federalNumber: { type:String, required: true},
    showSummary: { type:Boolean, default:true },
    showYear: { type: Boolean, default: false },
    showLicenceNumber: { type:Boolean, default: false },
  },
  data(){
    var curYear = new Date().getFullYear()
    return {
        currentYear: curYear,
        d_year: this.year || curYear,
        d_federal_number: this.federalNumber,
        d_people_id: this.people_id,
        d_people_name: this.peopleName,

        d_showSummary : this.showSummary,
        d_showYear: this.showYear,
        d_showLicenceNumber: this.showLicenceNumber,

        d_isValid: false,
        d_isCompetitor: false,
        d_isMedicalCertificateValid: false,

        d_dataSynchronized: false,

        d_disabled: true,
    }
  },
  methods:{
    async refresh(){
        var licensee = LICENSEE.query().where("year", this.d_year).where("federal_number", this.d_federal_number).first();
        //console.log('licensee', licensee, this.d_year, this.d_federal_number);
        this.d_isValid = (licensee != null) ? licensee.isValid : false;
        this.d_isCompetitor = (licensee != null) ? licensee.isCompetitor : false;
        this.d_isMedicalCertificateValid = (licensee!=null) ? licensee.isMedicalCertificateValid : false;

        this.d_disabled = (this.d_year != this.currentYear);
    },
    isValid_change(newValue){
        this.d_isValid = newValue;
        this.$emit('change', { id: this.d_people_id, federal_number: this.d_federal_number, year: this.d_year, isValid: this.d_isValid, isCompetitor: this.d_isCompetitor, isMedicalCertificateValid: this.d_isMedicalCertificateValid });
    },
    isCompetitor_change(newValue){
        this.d_isCompetitor = newValue;
        this.$emit('change', { id: this.d_people_id, federal_number: this.d_federal_number, year: this.d_year, isValid: this.d_isValid, isCompetitor: this.d_isCompetitor, isMedicalCertificateValid: this.d_isMedicalCertificateValid });
    },
    isMedicalCertificateValid_change(newValue)
    {
        this.d_isMedicalCertificateValid=newValue;
        this.$emit('change', { id: this.d_people_id, federal_number: this.d_federal_number, year: this.d_year, isValid: this.d_isValid, isCompetitor: this.d_isCompetitor, isMedicalCertificateValid: this.d_isMedicalCertificateValid });
    }
  },
  async mounted(){
    await this.refresh();
  },
  watch:{
    people_id: function(newVal)
    {
        this.d_people_id = newVal;
    },
    peopleName: function(newVal){
        this.d_people_name = newVal;
    },
    federalNumber: async function(newVal){
        this.d_federal_number = newVal;
        await this.refresh();
    },
    year: async function(newVal){
        this.d_year = newVal;
        await this.refresh();
    },
    showSummary: function(newVal){
        this.d_showSummary = newVal;
    },
    showYear: function(newVal){
        this.d_showYear = newVal;
    },
    showLicenceNumber: function(newVal){
        this.d_showLicenceNumber = newVal;
    }
  }
}
</script>

<style scoped>
    .form-group.row-padding{ margin-bottom: 0px !important;}
</style>